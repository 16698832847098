import styles from "./change-user-popup.module.scss";
import Modal from "../../modal/modal";
import InputComponent from "../../input/input";
import React, { FC, useState } from "react";
import { IPopup } from "@/services/types/types";
import ButtonComponent from "@/components/button/button";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { getUserInfo, updateUser } from "@/services/redux/features/userSlice";
import { callMessage } from "@/utils/functions/callMessage";

interface IValues {
  name: string;
  last_name: string;
  address: string;
}

export const ChangeUserPopup: FC<IPopup> = ({ isOpen, onClose }) => {
  const user = useAppSelector(getUserInfo).info;
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(user.name);
  const [lastName, setLastName] = useState<string>(user.lastname);
  const [address, setAddress] = useState<string>(user.address);
  const [isLoading, setIsLoading] = useState(false);

  const handleBlur = (e: React.FormEvent) => {
    e.preventDefault();
    const values: IValues = {
      name: name,
      last_name: lastName,
      address: address,
    };
    if (!values.name) {
      return callMessage("Ошибка!", "Заполните поле имя.", false);
    }
    if (!values.last_name) {
      return callMessage("Ошибка!", "Заполните поле фамилия.", false);
    }
    if (!values.address) {
      return callMessage("Ошибка!", "Заполните поле адрес.", false);
    }
    setIsLoading(true);
    dispatch(updateUser(values)).then(() => {
      setIsLoading(false);
      onClose();
    });
  };
  return (
    <Modal isOpen={isOpen} title={"Личные данные"} onClose={onClose}>
      <div className={styles.wrap}>
        <form className={styles.form} onSubmit={handleBlur}>
          <InputComponent
            id={"name-input"}
            label={"Имя"}
            className={styles.inputWrap}
          >
            <>
              <input
                id="name-input"
                type="text"
                maxLength={50}
                className={styles.input}
                placeholder=" "
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                autoComplete="off"
              />
            </>
          </InputComponent>
          <InputComponent
            id={"lastname-input"}
            label={"Фамилия"}
            className={styles.inputWrap}
          >
            <input
              id="lastname-input"
              type="text"
              maxLength={50}
              className={styles.input}
              placeholder=" "
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              name="last_name"
              autoComplete="off"
            />
          </InputComponent>
          <InputComponent
            id={"address-input"}
            label={"Адрес доставки"}
            className={styles.inputWrap}
          >
            <input
              id="address-input"
              type="text"
              maxLength={100}
              className={styles.input}
              placeholder=" "
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              name="personal_street"
              autoComplete="off"
            />
          </InputComponent>
          <ButtonComponent
            type="submit"
            className={styles.button}
            disabled={isLoading}
          >
            <>Изменить</>
          </ButtonComponent>
        </form>
      </div>
    </Modal>
  );
};
