import styles from "./seller-popup.module.scss";
import Modal from "../../modal/modal";
import { FC } from "react";
import { phoneFormat } from "@/utils/functions/phone-format";

export const SellerPopup: FC<any> = ({ isOpen, onClose, sellerInfo }) => {
  return (
    <Modal
      isOpen={isOpen}
      title={
        sellerInfo.phone ? phoneFormat(sellerInfo.phone) : "Телефон не указан"
      }
      onClose={onClose}
    >
      <div className={styles.wrap}>
        <p className={styles.text}>
          Имя: {sellerInfo.name ? sellerInfo.name : "Имя не указано"}
        </p>
        <p className={styles.text}>
          Email: {sellerInfo.email ? sellerInfo.email : "Email не указан"}
        </p>
        <p className={styles.text}>
          Не сообщайте продавцу данные своей банковской карты.
        </p>
      </div>
    </Modal>
  );
};
