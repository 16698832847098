import styles from "./category.module.scss";
import {FC, useState} from "react";
import Radio from "@/components/radio/radio";
import {ICategory} from "@/services/types/types";

interface ICategoryComponent {
  category: {
    code: string;
    name: string;
  };
  setChoosed: (c: string) => void;
  subCategories: any;
}

export const Category: FC<ICategoryComponent> = (props) => {
  const {
    category,
    setChoosed,
    subCategories,
  } = props;
  const [currentSubCategories, setCurrentSubCategories] = useState([]);
  const handleButtonClick = () => {
    setChoosed(category.code);
    setCurrentSubCategories(
      subCategories.find((cat: ICategory) => cat.code === category.code)?.subdirectories
    );
  };
  return (
    <div className={styles.wrap}>
      <Radio
        id={`category_subsection--${category.code}`}
        name="category_subsection"
        value={category.code}
        label={category.name}
        labelClassName={styles.radioButton}
        onClick={handleButtonClick}
        reverse
      />
      {currentSubCategories &&
        currentSubCategories.length > 0 &&
        currentSubCategories.map((category, index: number) => (
          <Category
            key={`${index + "category" + index + "categorykey"}`}
            category={category}
            setChoosed={setChoosed}
            subCategories={currentSubCategories}
          />
        ))}
    </div>
  );
};
