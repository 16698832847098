import React, { FC, ForwardedRef } from "react";
import styles from "./radio.module.scss";
import classNames from "classnames";

interface IRadio {
  id?: string;
  name?: string;
  label?: string;
  value?: string | number | readonly string[];
  checked?: boolean;
  labelClassName?: string;
  className?: string;
  reverse?: any;
  onClick?: () => void;
  check?: boolean;
  onChange?: () => void;
  ref?: ForwardedRef<HTMLDivElement>;
}

const Radio: FC<IRadio> = React.forwardRef((props, ref) => {
  const {
    id = "",
    name = "",
    label = "",
    value = "",
    checked,
    labelClassName = "",
    className = "",
    reverse = "",
    onClick = () => { },
    onChange = () => { },
    check
  } = props;
  const radioClassnames = classNames({
    [styles.radioInput]: true,
    [styles.radioInputReverse]: reverse,
  });
  return (
    <div className={className} onClick={onClick}>
      <input
        className={radioClassnames}
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={checked}
        checked={check}
        onChange={onChange}
        ref={ref}
      />
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
    </div>
  );
});
Radio.displayName = "Radio";
export default Radio;