"use client";
import {FC,  useEffect, useState} from "react";
import styles from "./code.module.scss";
import CodeInput from "@/components/code-input/code-input";
import ButtonComponent from "@/components/button/button";
import {useAppDispatch, useAppSelector} from "@/services/redux/hooks";
import {
  getAuthStatus,
  getAuthVariant,
  loginEmail,
  loginTel,
  setAuthStatus,
} from "@/services/redux/features/userSlice";
import moment from "moment";
import {phoneFormat} from "@/utils/functions/phone-format";
import {EmailCodeIcon} from "@/components/icon";

export const Code: FC = () => {
  const variant = useAppSelector(getAuthVariant);
  const dispatch = useAppDispatch();
  const timeCodeSent = useAppSelector(getAuthStatus).timeStampCode as any;
  const value = useAppSelector(getAuthStatus).valueToSendCode as any;
  const [timeToActiveButton, setTimeToActiveButton] = useState(
    moment(moment(timeCodeSent).unix()).diff(moment().unix())
  );
  const handleClick: any = (e: any) => {
    e.preventDefault();
    if (variant === "tel") {
      return dispatch(
        loginTel({
          value: value.slice(1),
        })
      );
    }
    if (variant === "email") {
      return dispatch(
        loginEmail({
          email: value,
        })
      );
    }
  };
  useEffect(() => {
    const timer =
      timeToActiveButton > 0 &&
      setInterval(() => setTimeToActiveButton(timeToActiveButton - 1), 1000);
    // @ts-ignore: Unreachable code error
    return () => clearInterval(timer);
  }, [timeToActiveButton]);
  useEffect(() => {
    setTimeToActiveButton(
      moment(moment(timeCodeSent).unix()).diff(moment().unix())
    );
  }, [timeCodeSent]);
  const backAuth = () => dispatch(setAuthStatus());
  return (
    <>
      <p className={styles.title}>Введите код.</p>
      <p className={styles.infoText}>
        {variant === "tel" && (
          <>
            На номер <span>{phoneFormat(value)}</span> поступит звонок. Введите{" "}
            <span>последние</span> 4 цифры входящего номера.
          </>
        )}
        {variant === "email" && (
          <>
            Мы отправили код подтверждения на почту <span>{value}</span>.
            Введите код из письма.
          </>
        )}
      </p>
      <form className={styles.form} action="#">
        <CodeInput className={styles.input}/>
        {variant === "tel" && (
          <div className={styles.expample_wrapper}>
            <p className={styles.text}>Звонок от ОхотАктив</p>
            <div className={styles.code_example}>
              <p>+7</p>
              <div className={styles.dots}>
                <span className={styles.dot}/>
                <span className={styles.dot}/>
                <span className={styles.dot}/>
              </div>
              <div className={styles.dots}>
                <span className={styles.dot}/>
                <span className={styles.dot}/>
                <span className={styles.dot}/>
              </div>
              <div className={styles.code}>
                <p>XXXX</p>
              </div>
            </div>
          </div>
        )}
        {variant === "email" && (
          <div className={styles.expample_wrapper}>
            <p className={styles.text}>Email от ОхотАктив</p>
            <div className={`${styles.code_example} ${styles.email}`}>
              <EmailCodeIcon className={styles.icon}/>
              <div className={styles.code}>
                <p>XXXX</p>
              </div>
            </div>
          </div>
        )}
        {timeToActiveButton <= 0 ? (
          // @ts-ignore: Unreachable code error
          <ButtonComponent onClick={handleClick} className={styles.button}>
            <>Отправить код повторно</>
          </ButtonComponent>
        ) : (
          <ButtonComponent className={styles.buttonDisabled}>
            <>Отправить код повторно: {timeToActiveButton}</>
          </ButtonComponent>
        )}
        <button className={styles.buttonBack} onClick={backAuth}>
          Вернуться назад
        </button>
      </form>
    </>
  );
};
