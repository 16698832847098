import { TModalHeaderComponent } from "@/services/types/types";
import styles from "./modal-header.module.scss";
import { FC } from "react";
import { ArrowIcon } from "@/components/icon";

const ModalHeader: FC<TModalHeaderComponent> = ({ title, goBack, onClose }) => {
  return (
    <div className={styles.modalHeader}>
      {goBack && (
        <button className={styles.title} onClick={onClose}>
          <ArrowIcon />
        </button>
      )}
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export default ModalHeader;
