import { IPopup } from "@/services/types/types";
import { FC, useRef, useState } from "react";
import Modal from "@/components/modal/modal";
import { useFetchData } from "@/services/hooks/useFetchData";
import { useAppSelector } from "@/services/redux/hooks";
import { getCity } from "@/services/redux/features/citySlice";
import { getDeliveryPointsRequest } from "@/utils/api/order";
import { Preloader } from "@/components/loading/preloader";
import { PointsList } from "./points-list/points-list";
import styles from "./choose-delivery-popup.module.scss";
// import PointsList from "./points-list/points-list";

export const ChooseDeliveryPopup: FC<IPopup> = ({
  isOpen,
  onClose,
  company,
}) => {
  const city = useAppSelector(getCity).city;
  const arg =
    company === "boxberry"
      ? city
      : company === "cdek"
      ? city
      : company === "pochta"
      ? city
      : "";
  const { isLoading, items } = useFetchData(getDeliveryPointsRequest, {
    company,
    arg,
  });
  const pointListRef = useRef(true);
  const [prepare, setPrepare] = useState(false);

  const handleClose = () => {
    setPrepare(true);
    const timer = setTimeout(() => onClose());
    return () => clearTimeout(timer);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={"Пункты самовывоза"}
      onClose={handleClose}
      className={styles.wrap}
      containerClassname={styles.container}
    >
      {isLoading && !prepare ? (
        <Preloader />
      ) : (
        <PointsList
          points={items}
          company={company}
          onClose={handleClose}
          prepare={prepare}
          setPrepare={setPrepare}
          pointListRef={pointListRef}
        />
      )}
    </Modal>
  );
};
