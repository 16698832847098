import { requestClient } from "../functions/request-client";

const OHOTAKTIV_API_PROD = process.env.NEXT_PUBLIC_OHOTAKTIV_API_PROD;
export const postAnnouncement = async (formData: any) => {
  return requestClient(OHOTAKTIV_API_PROD + "/account/announcement/index.php", {
    method: "POST",
    body: formData
  })
}

export const editAnnouncement = async (id: string | number, formData: any) => {
  if (!id) return
  return requestClient(OHOTAKTIV_API_PROD + `/account/announcement/index.php?id=${id}`, {
    method: "POST",
    body: formData
  })
}

export const deleteImageAnnouncement = async (picture: string) => {
  return requestClient(OHOTAKTIV_API_PROD + `/account/announcement/index.php?pic=${picture}`, {
    method: "delete",
  })
}