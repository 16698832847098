"use client";
import classNames from "classnames";
import { FC } from "react";
import Select from "react-select";
import "./select.scss";

interface ISelectComponent{
  
}

export const SelectComponent: FC<any> = ({
  isSearchable,
  options,
  name,
  className,
  isChoosed,
  onChange,
  isInForm,
  placeholder,
  defaultValue = '',
}) => {
  const selectClassnames = classNames({
    "react-select-container": isInForm,
    [className!]: className,
  });
  return (
    <Select
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: "none",
        }),
      }}
      classNamePrefix="react-select"
      className={selectClassnames}
      name={name}
      instanceId={name}
      isSearchable={isSearchable}
      options={options}
      defaultValue={defaultValue || options[isChoosed]}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
