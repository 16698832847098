import { AccordionItem as Item } from "@szhsin/react-accordion";
import styles from "./accordion-item.module.scss";
import { ChevronIcon } from "../icon";

export const AccordionItem = ({header, buttonClick, ...rest}: any) => {
  const buttonProps = () => {
    const props: any = {
      className: ({isEnter}) =>
        `${styles.itemButton} ${isEnter && styles.itemButtonOpened}`,
    };
    if (buttonClick) {
      props.onClick = buttonClick;
    }
    return props;
  };

  return (
    <Item
      {...rest}
      header={
        <>
          {header}
          <ChevronIcon className={styles.chevron}/>
        </>
      }
      className={
        rest.className ? styles.item + " " + rest.className : styles.item
      }
      // Сделал так, потому что типы явно не прописаны, и в одном месте потребовалось обработать
      // клик кастомно, поэтому так
      // если меняешь проверь везде ли работает
      // обрати внимание на каталог и <AvailablePopup/>
      buttonProps={buttonProps()}
      contentProps={
        {
          className: styles.itemContent,
          // style: {
          //   display: !!rest.isOpenContent && '',
          // }
        }
      }
      panelProps={{className: styles.itemPanel}}
    />
  );
};
