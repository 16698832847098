import { callMessage } from "./callMessage";

interface IList {
  [key: string]: string;
}

const list: IList = {
  city: "Город",
  condition: "Состояние",
  description: "Описание",
  files: "Фотографии",
  kalibr: "Калиб",
  length: "Длина ствола",
  manufacturer: "Произовдитель",
  material: "Материал",
  model: "Модель",
  price: "Цена",
  type: "Вид оружия",
  weight: "Вес",
};

export const checkUploadForm = (data: any) => {
  for (const key in data) {
    if (key === "files") {
      if (
        Object.keys(data.files).length < 1 ||
        Object.keys(data.files).length > 6
      ) {
        let messegeError =
          (Object.keys(data.files).length < 1 &&
            "Вы добавили менее трех фотографий") ||
          (Object.keys(data.files).length > 6 &&
            "Вы добавили более 6 фотографий");
        callMessage("Ошибка!", String(messegeError), false);
        return false;
      }
    }
    let size = 0;
    for (let i = 0; i < data.files.length; i++) {
      size += data.files[i].size;
      if (size > 16 * 1024 * 1024) {
        callMessage(
          "Ошибка!",
          "Общий размер фотографий не должен превышать 16 мб",
          false
        );
        return false;
      }
    }
    if (!!data[key]) {
    } else {
      callMessage("Ошибка!", `Поле «${list[`${[key]}`]}» не заполнено`, false);
      return false;
    }
  }
  return true;
};
