import { FC } from "react";
import ReactInputMask from "react-input-mask";
import InputComponent from "../input/input";
import { forwardRef } from "react";

const EmailInput: FC = forwardRef(function MyInput(props, ref) {
  const { id, className, label, icon, placeholder, onChange }: any = props;
  return (
    <InputComponent id={id} className={className} label={label} icon={icon}>
      <ReactInputMask
        id={id}
        placeholder={placeholder}
        alwaysShowMask={false}
        // @ts-ignore: Unreachable code error
        ref={ref}
        onChange={onChange}
      />
    </InputComponent>
  );
});

export default EmailInput;
