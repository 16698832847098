"use client";
import styles from "./analog-popup.module.scss";
import Modal from "../../modal/modal";
import InputComponent from "../../input/input";
import { FC, useState } from "react";
import { IPopup } from "@/services/types/types";
import ButtonComponent from "@/components/button/button";
import { postAnalog } from "@/utils/api/postAnalog";
import TelInput from "@/components/tel-input/tel-input";
import { analogValidate } from "@/utils/functions/analogValidate";
import { googleSendAnalog } from "@/utils/api/google-analytic";

export const AnalogPopup: FC<IPopup> = (props) => {
  const { isOpen, onClose } = props;
  const [name, setName] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (e: any) => {
    e.preventDefault();
    if (analogValidate({ name, tel, email })) {
      setIsLoading(true);
      let cleanedTel = tel.replace(/[+7,-]/g, "");
      cleanedTel = cleanedTel.replace(/\s/g, "");
      postAnalog({
        name,
        tel: cleanedTel,
        email,
        url: window.location.href,
      }).then(() => setIsLoading(false));
      ym(42989679, "reachGoal", "analog");
      googleSendAnalog();
      onClose();
    } else {
      return;
    }
  };
  return (
    <Modal isOpen={isOpen} title={"Подобрать аналог"} onClose={onClose}>
      <div className={styles.wrap}>
        <h2 className={styles.subtitle}>Заказать звонок для подбора аналога</h2>
        <form className={styles.form} action="#">
          <InputComponent
            id={"name-input"}
            label={"Введите ваше имя"}
            className={styles.inputWrap}
          >
            <input
              id="name-input"
              type="text"
              className={styles.input}
              placeholder=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputComponent>
          <TelInput
            id={"tel-input"}
            label={"Введите номер телефона"}
            placeholder=" "
            value={tel}
            className={styles.inputWrap}
            onChange={(e) => setTel(e.target.value)}
          />
          <InputComponent
            id={"email-input"}
            label={"Введите ваш email"}
            className={styles.inputWrap}
          >
            <input
              id="email-input"
              type="text"
              className={styles.input}
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputComponent>
          <ButtonComponent
            className={styles.button}
            onClick={handleClick}
            disabled={isLoading}
          >
            <p className={styles.text}>Заказать звонок</p>
          </ButtonComponent>
        </form>
      </div>
    </Modal>
  );
};
