import styles from "./warranty-popup.module.scss";
import Modal from "../../modal/modal";
import { FC } from "react";
import { IPopup } from "@/services/types/types";

export const WarrantyPopup: FC<IPopup> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} title={"Гарантия ОхотАктив"} onClose={onClose}>
      <div className={styles.wrap}>
        <p className={styles.text}>
          Гарантия 5 лет (золотая гарантия) распространяется на:
        </p>
        <ul className={styles.list}>
          <li className={styles.item}>Товар с явным заводским дефектом;</li>
          <li className={styles.item}>Неисправность по вине производителя.</li>
        </ul>
        <p className={styles.text}>
          Чтобы воспользоваться гарантией вам необходимо:
        </p>
        <ul className={styles.list}>
          <li className={styles.item}>
            Написать обоснованное требование о замене неисправных деталей,
            выполнение ремонта или обмене на новую единицу товара;
          </li>
          <li className={styles.item}>
            Предоставить изделие в заводской комплектации, с чеком о покупке и
            заполненным гарантийным талоном.
          </li>
        </ul>
        <p className={styles.text}>
          В этом случае «ОхотАктив» гарантирует бесплатную замену неисправных
          комплектующих или обмен товара.
        </p>
        <button className={styles.button} onClick={onClose}>
          Понятно
        </button>
      </div>
    </Modal>
  );
};
