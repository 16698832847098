import { callMessage } from "./callMessage";

export const phoneValidate = (str: string) => {
  if (!str) {
    callMessage(
      "Ошибка!",
      "Проверьте правильность ввода номера телефона.",
      false
    );
    return false;
  }
  let a = str.replace(/[+\s-/\_]/g, "").replace(/^7/, "");
  if (a[0] !== "9") {
    callMessage(
      "Ошибка!",
      "Проверьте правильность ввода номера телефона.",
      false
    );
    return false;
  } else if (a.length !== 10) {
    callMessage(
      "Ошибка!",
      "Проверьте правильность ввода номера телефона.",
      false
    );
    return false;
  } else {
    return true;
  }
};
