import { request } from "../functions/request";

export const checkOrderStatus = async (orderId: string | number) => {
  return await request(`/order/status/?order_id=${orderId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
};
