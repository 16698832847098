import classNames from "classnames";
import styles from "./input.module.scss";
import {IInputComponent} from "@/services/types/types";
import {FC} from "react";

const InputComponent: FC<IInputComponent> = (props) => {
  
  const {
    children,
    icon,
    label,
    required,
    id,
    className,
  } = props;
  
  const inputWrapClassnames = classNames(
    `${className ? className + " " : ""}${styles.wrap}`
  );
  
  return (
    <div className={inputWrapClassnames}>
      {children}
      {icon && icon}
      <label className={styles.label} htmlFor={id}>
        {label}
        {required && "*"}
      </label>
    </div>
  );
};

export default InputComponent;
