"use client";
import styles from "./status-order-popup.module.scss";
import Modal from "../../modal/modal";
import InputComponent from "../../input/input";
import {FC, FormEvent, useState} from "react";
import {IPopup} from "@/services/types/types";
import ButtonComponent from "@/components/button/button";
import {checkOrderStatus} from "@/utils/api/checkOrderStatus";
import {callMessage} from "@/utils/functions/callMessage";

export const StatusOrderPopup: FC<IPopup> = (props) => {
  const {isOpen, onClose} = props;
  const [orderId, setOrderId] = useState<string>("");
  const [checkStatus, setCheckStatus] = useState<boolean>(false);
  const [responseReceived, setResponseReceived] = useState<boolean>(false);
  const [response, setResponse] = useState<string>("");
  const handleClick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCheckStatus(true);
    let newOrderId = orderId.split(".")[0];
    setOrderId(newOrderId);
    checkOrderStatus(orderId)
      .then((res: any) => {
        ym(42989679, "reachGoal", "checkStatus");
        if (res.success && res.order_status !== null) {
          setCheckStatus(false);
          setResponseReceived(true);
          setResponse(res.order_status);
        } else {
          callMessage("Ошибка!", "Ошибка в номере заказа.", false);
          setCheckStatus(false);
          setResponseReceived(false);
        }
      })
      .catch((error) => {
        setCheckStatus(false);
      });
  };
  const back = () => {
    setCheckStatus(false);
    setResponseReceived(false);
    setResponse("");
    setOrderId("");
  };
  return (
    <Modal isOpen={isOpen} title={"Статус заказа"} onClose={onClose}>
      <>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleClick(e)}
          className={`
          ${styles.wrap} 
          ${checkStatus && styles.check}
          ${responseReceived && styles.response}
        `}
        >
          <InputComponent
            id={"status-input"}
            label={"Введите номер заказа"}
            className={styles.inputWrap}
          >
            <input
              id={"status-input"}
              type="number"
              maxLength={7}
              className={styles.input}
              placeholder=" "
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </InputComponent>
          <ButtonComponent
            className={styles.button}
            type="submit"
            disabled={checkStatus}
          >
            <p className={styles.text}>Проверить</p>
          </ButtonComponent>
        </form>
        {responseReceived && (
          <div className={styles.wrap}>
            <p className={styles.order_id}>Заказ №{orderId}</p>
            <div className={styles.order_info}>
              <p className={styles.status}>Статус</p>
              <p className={styles.order_response}>{response}</p>
            </div>
            <ButtonComponent className={styles.button} onClick={back}>
              <p className={styles.text}>Вернуться назад</p>
            </ButtonComponent>
          </div>
        )}
      </>
    </Modal>
  );
};
