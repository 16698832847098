import styles from "./index.module.scss";
import { SkeletonLoader } from "@/components/skeleton-loader/skeleton-loader";

const GeoPopupSkeleton = () => {
  return (
    <div className={styles.wrap}>
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={42}
        mb={24}
        borderRadius={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={46}
        mb={12}
        borderRadius={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={24}
        borderRadius={8}
        mb={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={24}
        borderRadius={8}
        mb={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={24}
        borderRadius={8}
        mb={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={24}
        borderRadius={8}
        mb={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={24}
        borderRadius={8}
        mb={8}
      />
      <SkeletonLoader
        containerTag={'div'}
        width={'100%'}
        height={24}
        borderRadius={8}
      />
    </div>
  );
};

export default GeoPopupSkeleton;