import styles from "./courier-popup.module.scss";
import Modal from "../../modal/modal";
import { FC } from "react";
import { IPopup } from "@/services/types/types";

export const CourierPopup: FC<IPopup> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} title={"Доставка курьером"} onClose={onClose}>
      <div className={styles.wrap}>
        <p className={styles.text}>
          Вы можете выбрать доставку курьером до двери или до пункта выдачи
          заказов любой транспортной компании. Стоимость доставки рассчитывается
          согласно тарифам выбранной вами транспортной компании.
        </p>
        <p className={styles.smallText}>
          * Не распространяется на лицензионные товары
        </p>
      </div>
    </Modal>
  );
};
