import { request } from "../functions/request";

export const postAnalog = async (data: any) => {
  return await request(`/send_application/?name=analog`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
