import styles from "./custom-tooltip.module.scss";
import { FC, useEffect, useRef, useState } from "react";

interface ICustomTooltip {
  text?: string;
  className?: string;
  children: React.ReactNode | JSX.Element;
  show?: boolean;
}
/** 
  @text maximum 20 characters
*/
export const CustomTooltip: FC<ICustomTooltip> = (props) => {
  const {
    text,
    className,
    children,
    show = true
  } = props;
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('top');
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef(null);

  const showTooltip = () => {
    if (!show) return
    setVisible(true);
    adjustTooltipPosition();
  };

  const hideTooltip = () => setVisible(false);

  const adjustTooltipPosition = () => {
    if (!tooltipRef.current) return
    if (!containerRef.current) return
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    if (tooltipRect.top < 0) {
      setPosition('bottom');
    } else if (tooltipRect.bottom > window.innerHeight) {
      setPosition('top');
    } else if (tooltipRect.left < 0) {
      setPosition('right');
    } else if (tooltipRect.right > window.innerWidth) {
      setPosition('left');
    } else {
      setPosition('top');
    }
  };

  useEffect(() => {
    if (visible) {
      adjustTooltipPosition();
    }
  }, [visible]);

  return (
    <div
      className={`${styles.tooltip_container} ${className}`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      ref={containerRef}
    >
      {children}
      <div className={`${visible ? styles.tooltip_box_active : styles.tooltip_box} ${styles[position]}`} ref={tooltipRef}>
        {text}
      </div>
    </div>
    // <div className={styles.tooltip_container} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
    //   {children}
    //   <div className={`${visible ? styles.tooltip_box_active : styles.tooltip_box}`}>
    //     {text}
    //   </div>
    // </div>
  );
};