import { SkeletonLoader } from "../skeleton-loader";
import styles from "./skeleton-crate-ad-popup.module.scss";
import { FC } from "react";

export const SkeletonCreateAdPopup: FC = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.items}>
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
          <SkeletonLoader
            containerTag="div"
            height={48}
            borderRadius={8}
          />
        </div>
        <div className={styles.price}>
          <SkeletonLoader
            containerTag="div"
            className={styles.price_block}
            height={126}
            borderRadius={8}
            mb={24}
          />
          <SkeletonLoader
            containerTag="div"
            height={104}
            mt={24}
            mb={24}
            borderRadius={8}
          />
        </div>
        <SkeletonLoader
          containerTag="div"
          height={48}
          mb={24}
          borderRadius={8}
          width={200}
        />
      </div>
    </div>
  );
};