const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export default function isValidEmail(email: string) {
  if (!email) return false;
  const trimmedEmail = email.trim();
  const emailParts = trimmedEmail.split("@");
  if (emailParts.length !== 2) return false;
  const account = emailParts[0];
  const address = emailParts[1];
  if (account.length > 64) return false;
  else if (address.length > 255) return false;
  const domainParts = address.split(".");
  if (
    domainParts.some(function (part: any) {
      return part.length > 63;
    })
  )
    return false;
  return tester.test(trimmedEmail);
}