export const translit = (str: string): string => {
  const russian: string[] = [
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Ё",
    "Ж",
    "З",
    "И",
    "Й",
    "К",
    "Л",
    "М",
    "Н",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Ш",
    "Щ",
    "Ъ",
    "Ы",
    "Ь",
    "Э",
    "Ю",
    "Я",
    "а",
    "б",
    "в",
    "г",
    "д",
    "е",
    "ё",
    "ж",
    "з",
    "и",
    "й",
    "к",
    "л",
    "м",
    "н",
    "о",
    "п",
    "р",
    "с",
    "т",
    "у",
    "ф",
    "х",
    "ц",
    "ч",
    "ш",
    "щ",
    "ъ",
    "ы",
    "ь",
    "э",
    "ю",
    "я",
    " ",
    ",",
    "+",
    "(",
    ")",
    '"',
    "&",
    "-",
    ".",
    "«",
    "»",
    "№",
    "°",
    "շ",
    "¶",
    "ö",
    "ä",
    "”",
    "®",
    "%",
    "<",
    ">",
  ];
  const translit: string[] = [
    "A",
    "B",
    "V",
    "G",
    "D",
    "E",
    "E",
    "Gh",
    "Z",
    "I",
    "Y",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "F",
    "H",
    "C",
    "Ch",
    "Sh",
    "Sch",
    "Y",
    "Y",
    "Y",
    "E",
    "Yu",
    "Ya",
    "a",
    "b",
    "v",
    "g",
    "d",
    "e",
    "e",
    "gh",
    "z",
    "i",
    "y",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "f",
    "h",
    "c",
    "ch",
    "sh",
    "sch",
    "lb",
    "bl",
    "b",
    "e",
    "yu",
    "ya",
    "_",
    "",
    "_",
    "",
    "",
    "*",
    "and",
    "",
    "",
    "",
    "",
    "number",
    "g",
    "2",
    "",
    "o",
    "a",
    "",
    "",
    "pr",
    "do",
    "ot",
  ];

  const regex = russian
    .map((char) => {
      return char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    })
    .join("|");

  return str
    .replace(
      new RegExp(regex, "g"),
      (match) => translit[russian.indexOf(match)]
    )
    .toLowerCase();
};
